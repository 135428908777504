.merits {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/mertis_bg.png');
    background-size: cover;
    background-position: center;
}

.merits-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.merits-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.merits-number {
    /* color: #189EFF; */
    /* border: 1.5px solid #189EFF; */
    font-weight: 900;
    font-size: 110px;
    text-shadow: -1px 0 #189EFF, 0 1px #189EFF, 1px 0 #189EFF, 0 -1px #189EFF;
    line-height: 135px;
}

.merits-line {
    width: 50px;
    height: 2px;
    background-color: #189EFF;
}

.merits-text {
    font-family: 'Roobert';
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: 15px;
}


@media screen and (max-width: 960px) {

    .merits-number {
        font-size: 90px;
        line-height: 135px;
    }
    
    .merits-line {
        width: 50px;
        height: 2px;
    }
    
    .merits-text {
        font-size: 14px;
        line-height: 24px;
        margin-top: 15px;
    }

}