.partners {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: center;
}

.partners-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

.partner {
    width: 150px;
    height: 100px;
    background-color: rgb(232, 232, 232);
    border-radius: 30px;
    vertical-align:middle; 
    text-align:center;
    display: flex;
}

.partner > img {
    object-fit: contain;
    max-width:80%;
    max-height:80%;
    margin: auto;
}





@media screen and (max-width: 960px) {

    .partners-container {
        flex-wrap: wrap;
        gap: 20px;
    }
    .partners {
        height: auto;
        padding: 30px 0px;
    } 
    .partner {
        min-width: 20vw;
    }


}