.title-container {
    width: 100%;
    text-align: center;
}



.title--primary {
    color: #189EFF;
}
.title--white {
    color: #fff;
}
.title--black {
    color: #000;
}


.title-text {
    font-weight: 300;
    font-size: 52px;
    text-align: center;
    letter-spacing: 0.07em;
    margin: 0 20px;
}

.title-icon {
    font-size: 30px;
    transform: translateY(-7px)
}


@media screen and (max-width: 960px) {

    .title-text {
        font-size: 36px;
    }
    .title-icon {
        font-size: 20px;
        transform: translateY(-7px)
    }

}