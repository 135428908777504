.hero-container {
    position: relative;
    /* width: 100%; */
    height: 100vh;
    max-height: 800px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-bg {
    position: absolute;
    background-image: url('../assets/bg1.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.hero-bg-ornaments {
    background-image: url('../assets/cube_line.png');
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-position: center bottom;
    background-repeat: repeat-x;
}

.hero-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    object-fit: contain;
    max-width: 1500px;
    width: 80vw;
    /* justify-content: center; */
}

.hero-texts {
    height: 100%;
    width: 50%;
    /* background-color: green; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.hero-texts-center {
    display: flex;
    flex-direction: column ;
    margin-left: 20px;
    row-gap: 15px;
}

.hero-text-1 {
    color: #189EFF;
    /* font-size: 54px; */
    font-size: clamp(16px, 4.5vw, 54px);
    line-height: 110%;
    font-weight: 300;
    margin-bottom: -15px;
}
.hero-text-2 {
    color: #1A1A1A;
    /* font-size: 84px; */
    /* font-size: 7.4vw; */
    font-size: clamp(16px, 7.4vw, 84px);
    line-height: 100%;
    font-weight: 700;
    text-transform: capitalize;
}
.hero-text-3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    font-family: 'Roboto';
    color: #323232;
    padding-top: 10px;
    padding-bottom: 10px;
}

.hero-description {
    height: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: stretch;
}

.blue-vertical {
    width: 6px;
    min-width: 6px;
    border-radius: 20px;
    background-color: #189EFF;
    margin-right: 20px;
}

.hero-visual {
    height: 100%;
    width: 50%;
    /* background-color: red; */
}

.hero-visual-art {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    background-image: url('../assets/visual_1.png')
}



@media screen and (max-width: 960px) {

    .hero-content {
        flex-direction: column-reverse;
    }
    .hero-visual {
        width: 100%;
        height:100%
    }
    .hero-visual-art {
        background-size: contain;
    }
    .hero-texts {
        text-align: center;
        width: 80vw;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .hero-texts-center {
        margin: 0 auto;
    }
    .blue-vertical {
        width: 30vw;
        height:5px;
        margin-right: 0px;
        margin-top: 10px;
    }
    .hero-description {
        text-align: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        height: auto;
    }
    .hero-btns {
        display: flex;
        align-items: center;
        text-align: center;
    }
    .btn-mobile {
       margin: 0 auto;
    }
    .hero-text-1 {
        font-size: 54px;
    }
    .hero-text-2 {
        font-size: 54px;
    }
    .hero-text-3 {
        font-size: 20px;
        line-height: 22px;
    }

}