.project-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    justify-content: space-around;
}
.project-container--reverse {
    width: 100%;
    display: flex;
    flex-direction: row-reverse; 
    /* justify-content: flex-start; */
    justify-content: space-around;
}


.project-visual {
    display: flex;
    /* flex-grow: 1; */
    min-width: 50%;
    max-width: 50%;
}

.project-text {
    display: flex;
    flex-direction: column;
    /* flex-grow: 10; */
    /* flex-wrap: wrap; */
    width: 40%;
}

.project-title {
    color: #189EFF;
    font-weight: 700;
    font-size: 44px;
}

.project-line {
    width: 40px;
    height: 3px;
    background-color: white;
    border-radius: 1px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.project-description {
    color: white;
    margin-bottom: 15px;
}

.project-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}





@media screen and (max-width: 960px) {

    .project-container, .project-container--reverse {
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    .project-visual {
        min-width: 100%;
        max-width: 100%;
    }
    .project-text {
        width: 100%;
    }
    .project-title {
        color: #189EFF;
        font-weight: 700;
        font-size: 36px;
        margin-top: 15px;
    }

}