.portfolio {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #0F121B 0%, #222733 100%);
}


.portfolio-ornaments-left {
    position: absolute;
    left: 0px;

    width: 50px; 
    height: 0; 
    border-bottom:     6px solid #181c26 ;
    border-left:    6px solid #050E25 ;
    border-right:   6px solid transparent;
    transform: translateY(-6px);
  }

.portfolio-ornaments-right {
    position: absolute;
    right: 0px;

    width: 100px; 
    height: 0; 
    border-bottom:     6px solid #050E25 ;
    border-right:    6px solid #050E25 ;
    border-left:   6px solid transparent;
    transform: translateY(-6px);
  }


.portfolio-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 80px;
}

.projects-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 75px;
}

.projects-sep {
    width: 100%;
    height: 1px;
    /* border-bottom: 1px dashed rgba(24, 158, 255, 0.7); */
    
    margin: 35px 0px;


    background-image: linear-gradient(to right, rgb(24, 158, 255) 15%, rgba(24, 158, 255, 0) 0%);
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;


    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 50' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e"); */
}

.projects-container {
    
}
