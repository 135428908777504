* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Titillium Web', 'Roboto', sans-serif;
  }

  body {
    background-color: #e7f1f9;
  }

  #root {
    /* background-color: #e7f1f9; */
  }
  
  .home,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
   
.hor-wrap {
  display: flex;
  /* justify-content: center; */
  width: 80vw;
  max-width: 1500px;
}


.multiline {
  white-space: pre-wrap;
}