.navbar {
    background: radial-gradient(31.69% 113.51% at 50% 0%, #2E3956 0%, #050E25 83.33%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 80px;
    max-width: 1500px;
    z-index: 100;
    justify-content: space-between;
  }

  .navbar-control-container {

  }

  .caps {
    text-transform: uppercase;
  }
  
  .navbar-ornaments-left {
    position: absolute;
    bottom: -6px;
    left: 0px;

    width: 100px; 
    height: 0; 
    border-top:     6px solid #181c26 ;
    border-left:    6px solid #050E25 ;
    border-right:   6px solid transparent;
  }

  .navbar-ornaments-right {
    position: absolute;
    bottom: -6px;
    right: 0px;

    width: 50px; 
    height: 0; 
    border-top:     6px solid #050E25 ;
    border-right:    6px solid #050E25 ;
    border-left:   6px solid transparent;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .navbar-logo-svg {
    width:125px;
    height:35px;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;
    width: auto;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 45px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    text-align: center;
    font-weight: 600;
  }

  .nav-links-btn {
    border: 1.85px solid rgba(0, 0, 0, 0);
    padding: 0.45rem 1.5rem;
    border-radius:100px;
    transition: border 0.2s;
  }

  .nav-links-lang {
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
    padding-left: 3rem;
    color: rgba(255, 255, 255, 0.65);
  }

   .nav-links-btn:hover {
    border: 1.85px solid #3095ff;
    transition: border 0.2s;
  }
  
  /* .nav-links:after {
    position: fixed;
    display: inline-block;
    border: 1.85px solid #fff;
    border-radius:100px;
    background-color: red;
    height: 30px;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-lang-arrow {
    margin-left: 5px;
  }

  .nav-lang-btn {
    display: none;
  }

  .nav-lang-btn-active:hover {
    color: white;
  }
  .nav-lang-btn-active:hover ~ .nav-lang-arrow {
    color: white;
  }

  .nav-lang-btn-active {
    display: block;
  }

  .nav-lang-dropdown {
    /* width: 75px; */
    /* height:100px; */
    position: absolute;
    top: 100px;
    background-color: #050E25;
    padding: 8px 20px;
    transform: translateX(-20px);
  }

  .nav-lang-dropdown-hide {
    display: none;
  }

  .nav-lang-dropdown-list:hover {
    color: white;
    border-bottom: solid 2px #3095ff;
    transition: all 0.2s;
  }

  .nav-lang-dropdown-list-hide {
    display: none;
  }


  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-lang-dropdown {
      display: none;
    }

    .nav-lang-btn {
      display: block;
      padding: 0px 10px
    }
      
    .nav-lang-btn-active {
      color: rgba(255, 255, 255, 1);
    }
    .nav-lang-btn:hover {
      border-bottom: solid 2px #3095ff;
      transition: all 0.2s;
      color: white;
    }

    .nav-lang-arrow {
      display: none;
    }

  
    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      gap: 2vh;
    }
  
    .nav-menu.active {
      background: #050E25;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 90;
    }
  
  
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6vh;
    }

    .nav-links {
      text-align: center;
      justify-content: center;
      padding: 0.05rem 2.5rem;
      /* width: 100%; */
    }
  
    /* .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    } */
  
    .navbar-logo {
      position: absolute;
      left: 0;
      transform: translateX(25%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      z-index: 100;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
/*   
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    } */
  
    /* .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    } */
  }