.contacts {
    width: 100%;
    height: 550px;
    background-image: url('../assets/contacts_bg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts-box {
    width: 450px;
    height: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.contacts-title {
    font-size: 36px;
    font-weight: 600;
    color: black;
}

.contacts-inputs {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    padding: 20px;
    border: none;
    background-color: #F2F2F2;
}

.contacts-message {
    height: 130px;
}
