:root {
    --primary: #000000;
    --secondary: #FFFFFF;
    --blue: #189EFF;
}

.social-icon {
    color: white;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 13px;
    text-decoration: none;
    transition: all 0.2s ease-out;
}

.btn--primary {
    background-color: var(--primary);
    color: var(--secondary);
    /* border: 1px solid var(--primary); */
}

.btn-icon {
    color: var(--blue);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.2s ease-out;
}

/* /////////////////////////////////////////////////// */

.btn--outline-blue {
    background-color: transparent;
    color: var(--blue);
    padding: 8px 20px;
    border: 1px solid var(--blue);
    transition: all 0.2s ease-out;
}
.btn--outline-blue:hover  {
    background: var(--blue);
    color: black;
    transition: all 0.2s ease-out;
}
.btn--outline-blue:hover .btn-icon {
    color: black;
}


/* /////////////////////////////////////////////////// */

.contacts-btn {
    background-color: var(--primary);
    color: var(--secondary);
    width: 100%;
    height: 60px;
    border-radius: 10px;
}

/* /////////////////////////////////////////////////// */

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 24px;
    line-height: 37px;
    border-radius: 19px;
}

/* .btn--medium:hover, .btn--large:hover {
    background: #1d1754;
    transition: all 0.2s ease-out;
} */



a:link {
    text-decoration: none;
  }

/* .btn-text {
} */