.solutions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.solutions-container {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    justify-content: center;
    margin-top:40px;
    /* width: 80vw; */
    flex-wrap: wrap;
    max-width: 1500px;
}