.offer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.offer-container {
    background-color: #189EFF;
    height: 350px;
    border-radius: 50px;
    align-items: center;
    font-size: 72px;
    font-weight: 300;
    flex-direction: row;
    justify-content: space-around;
    background-image: url('../assets/offer_bg.png');
    background-position: center;
    background-size: cover;
}


.offerText {
    color: white;
}



@media screen and (max-width: 960px) {

    .offer-container {
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        font-size: 48px;
        height: 250px;
    }

}
