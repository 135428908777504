

.service-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: stretch;
    height: auto;
    background: linear-gradient(180deg, rgba(249 252 255 0.5) 0%, rgba(221 239 255 0.5) 68.75%, rgba(244, 249, 255, 0.5) 100%);
    box-shadow: 0px 3.42127px 10.2638px 3.42127px rgba(18, 36, 51, 0.075), inset 0px 0px 8.55317px 4.27659px rgba(255, 255, 255, 0.425);
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    /* flex-grow: 4; */
    /* min-height: 80px;; */
    min-width: 400px;
    cursor: pointer;
}

.service-top {
    min-height: 100px;
}

.service-main {
    display: flex;
    font-size: 26px;
    font-weight: 400;
    line-height: 120%;
    align-items: center;
    color: #000000;
    width: 100%;
    justify-content: center;
    gap: 20px;
}

.service-title {
    flex-grow: 10;
}

.service-description {
    font-size: 22px;
    font-weight: 400;
    color: #616161;
    left: 20px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 25px;
}


.serice-hide {
    display: none;
}

.service-center {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}




.service-visual {
    display: flex;
    align-self: flex-start;
    align-items: center;
    width: 100px;
    min-width: 100px;
    /* background-color: red; */
}

.service-visual > img {
    width: 70%;
}

.serice-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    flex-grow:10;
}
.service-icon {
    display: flex;
    color: #878787;
    align-items: center;
    opacity: 0.5;
}



.hide {
    display: none;
}





/* --- BLUE STYLE --- */

.service--blue {
    background-color: #189EFF;
    box-shadow: none;
}

.service--blue .service-main {
    color: white;
}

.service--blue img {
    filter: brightness(8) contrast(1) saturate(0);
}

.service--blue .service-icon {
    color: white;
}

.service--blue .service-description {
    color: white;
}
