.bottom {
    width: 100%;
    height: 200px;
    background-image: url('../assets/contacts_bg.png');
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
}

.bottom-container {
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top:40px;
}

.bottom-social-icon {
    margin-left: 40px;
    cursor: pointer;
    transition: all 0.15s;
}

.bottom-social-icon:hover {
    color: #189EFF;
}

.bottom-social {
    display: flex;
    flex-direction: row;
}

.bottom-links {
    display: flex;
    flex-direction: column;
}